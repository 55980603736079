<template>
  <section id="welcome" class="overflow-hidden">
    <v-row no-gutters>
      <v-col class="hidden-sm-and-down" md="6">
        <v-img :src="require('@/assets/welcome.jpg')" height="100vh" />
      </v-col>

      <v-col
        class="align-content-space-between layout wrap"
        cols="12"
        md="6"
        :pa-5="$vuetify.breakpoint.smAndDown"
      >
        <BaseBubble1 style="transform: rotate(180deg) translateY(25%)" />

        <v-row align="center" justify="center">
          <v-col cols="10" md="6">
            <BaseHeading>AIRBUDDY</BaseHeading>
            <base-text>
              La EPOC es la cuarta causa de muerte y la
              tercera causa de comorbilidad asociada a muerte por COVID19:<br />
              AirBuddy ofrece una serie de soluciones tecnológicas para
              ayudar a técnicos sanitarios y pacientes a afrontar la EPOC y
              mejorar la salud del paciente.
            </base-text>
            <v-btn text class="mt-4" @click="$router.push('/login')">
              Prueba la App
            </v-btn>
            <v-btn text class="mt-4" @click="$vuetify.goTo('#services')">
              Leer mas! <v-icon>mdi-chevron-double-down</v-icon>
            </v-btn>
          </v-col>
        </v-row>

        <base-bubble-1
          style="transform: rotate(180deg) translate(-200px, -15%)"
        />
      </v-col>
    </v-row>
  </section>
</template>

<script>
import BaseHeading from '@/components/base/Heading'
import BaseBubble1 from '@/components/base/Bubble1'
import BaseText from '@/components/base/Text'

export default {
  components: {
    BaseBubble1,
    BaseText,
    BaseHeading
  }
}
</script>

<style lang="scss" scoped>
</style>
